<template>
  <div class="404">
    404
  </div>
</template>

<script>
export default {
  name: "404",
  data() {
    return {}
  },
  create() {
  },
  mounted() {
  },
  computed() {
  },
  methods: {}
}
</script>

<style scoped>

</style>
